import React from 'react';
import './ShelfView.scss';
import QuickPinchZoom, { make3dTransformValue } from "../../PinchZoom";

class ShelfView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {selectedPaletteCode: null}
        this.onZoom = this.onZoom.bind(this);
        this.onPaletteClick = this.onPaletteClick.bind(this);
        this.filteredProductsByPalette = this.filteredProductsByPalette.bind(this);
        this.pinchZoomRef = React.createRef();
        this.mapRef = React.createRef();
    }

    getMinZoom() {
        return window.innerWidth/this.props.stand.shelf_width;
    }

    getInitialScale() {
        return {x: 0, y: 0, scale: this.getMinZoom()};
    }

    componentDidMount() {
        if(this.props.stand) {
            this.pinchZoomRef.current?.scaleTo(this.getInitialScale());
        }
    }

    onZoom = ({x, y, scale}) => {
        const shelf = document.getElementById('shelf');
        if(!shelf) {
            return;
        }
        shelf.style.setProperty('transform', make3dTransformValue({x, y, scale}));
    }

    onPaletteClick = (paletteCode) => {
        const currentSelection = this.state.selectedPaletteCode;
        if(currentSelection === paletteCode) {
            this.setState({selectedPaletteCode: null});
            this.props.selectedPaletteCode(null);
        }
        else {
            this.setState({selectedPaletteCode: paletteCode});
            this.props.selectedPaletteCode(paletteCode);
        }
    }

    filteredProductsByPalette = (paletteId) => {
        var filtered = this.props.stand.products.filter((product) => {
            return product.palette_id === parseInt(paletteId);
        });
    }

	render() {
		const { stand } = this.props;

		let rows = [];
		let minZoom = this.getMinZoom();

	    for (var i = 0; i < stand.s; i++){
	      let cell = [];

	      for (var idx = 0; idx < stand.p; idx++) {
	        let _class = '';
	        let paletteCode = `${stand.palettes[stand.p*(stand.s-i-1) + (idx)].code}`;
	        let paletteId = `${stand.palettes[stand.p*(stand.s-i-1) + (idx)].id}`;

            let filtered = stand.products.filter((product) => {
                return product.palette_id === parseInt(paletteId);
            });

	        if(paletteCode === this.state.selectedPaletteCode) {
                _class = ' selected';
            }
            else {
                if(filtered.length > 0) {
                    _class = ' filled';
                }
            }

	        cell.push(
	        	<td key={idx}>
	        		<div id={paletteCode} onClick={() => this.onPaletteClick(paletteCode)}
	        		    className={_class}
	        		    {...{ "paletteid": `${paletteId}` }}
	        		>{paletteCode}</div>
	        	</td>
	        );
	      }
	      rows.push(<tr key={i}>{cell}</tr>);
	    }

	    return (
		    <QuickPinchZoom
		        onUpdate={this.onZoom}
                maxZoom={3.5}
                minZoom={minZoom}
                zoomOutFactor={minZoom}
                ref={this.pinchZoomRef}
                inertia={false}
            >
                <div id="shelf" ref={this.mapRef} className="shelf-container">
                    <table>
                        <tbody>{rows}</tbody>
                    </table>
				</div>

            </QuickPinchZoom>
		);
	}
}

export default ShelfView;