import React from 'react';
import './ScanBarcodeButton.scss';


class ScanBarcodeButton extends React.Component {
    render(){
        return (
            <span className="btn" onClick={this.props.onClick}>
                <img src='/static/icons/barcode.svg' alt='barcodeIcon' />
            </span>
        );
    }
}

export default ScanBarcodeButton;