import React, { Component } from 'react';
import LoginForm from './components/LoginForm/LoginForm';
import Orders from './components/Orders/Orders';
import OrderDetail from './components/OrderDetail/OrderDetail';
import Inventory from './components/Inventory/Inventory';
import Moving from './components/Moving/Moving';
import AddItem from './components/AddItem/AddItem.js';
import { Redirect } from 'react-router';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Context from './components/Context/Context';
import axios from 'axios';

class App extends Component {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    this.state = {
      logged_in: localStorage.getItem('token') ? true : false,
      username: '',
      error: '',
      stand: {},
      update: this.updateState
    };
  }

  updateState(newState) {
    this.setState(newState);
  }

  componentDidMount() {
    if (this.state.logged_in) {
      axios.get(`${process.env.REACT_APP_API_URL}/api/current_user/`, {
        headers: {
          //'Content-Type': 'application/json',
          //'X-Requested-With': 'XMLHttpRequest',
          //'Accept' : 'application/json',
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
      .then(res => { this.setState({ username: res.data }); })
    }
  }

  handle_login = (e, data) => {
    e.preventDefault();
    axios.post(`${process.env.REACT_APP_API_URL}/token-auth/`, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(res => {
        if(res.status === 200) {
            localStorage.setItem('token', res.data.token);
            this.setState({
              logged_in: true,
              error: ''
            });
        } else {
            this.setState({error: res.data.non_field_errors[0]});
        }
    });

  };

  handle_logout = () => {
    localStorage.removeItem('token');
    this.setState({ logged_in: false, username: '' });
  };

  render() {

    return (
      <div className="App" style={{backgroundColor: this.state.logged_in ? 'white':'#2CB0FA'}}>
          {!this.state.logged_in
            ? (<LoginForm handle_login={this.handle_login} error={this.state.error} />)
            : (
                <Router>
                    <Context.Provider value={this.state}>
                        <Switch>
                          <Route path="/add-item/:id">
                            <AddItem onLogout={this.handle_logout} />
                          </Route>
                          <Route path="/moving/:id">
                            <Moving onLogout={this.handle_logout} />
                          </Route>
                          <Route path="/inventory/:id">
                            <Inventory onLogout={this.handle_logout} />
                          </Route>
                          <Route path="/orders/:id">
                              <OrderDetail onLogout={this.handle_logout} />
                          </Route>

                          <Redirect exact from="/" to="/orders" />
                          <Route path="/orders">
                              <Orders onLogout={this.handle_logout} />
                          </Route>
                          <Route path="/logout" render={() => { this.handle_logout() }} />
                        </Switch>
                    </Context.Provider>
                </Router>
             )
          }
      </div>
    );
  }
}

export default App;