import React from 'react';
import './OrderInfo.scss';


class OrderInfo extends React.Component {

    switchColor = (status) => {
        switch(status) {
          case 1:
            return 'yellow';
          case 2:
            return 'green';
          default:
            return 'blue';
        }
    }

    render() {

        let color =  this.switchColor(this.props.status);
        let status = {
          0: 'Not Started',
          1: 'In Progress',
          2: 'Ready',
        };
        return(
          <div className="block-info">
            <div className="block-info__column">
              <div className="block-info__label">Order name / ID</div>
              <div className="block-info__title">{this.props.name}</div>
              <div className={"die die--" + color}>{status[this.props.status]}</div>
            </div>

            <div className="block-info__column">
              <div className="block-info__label">Due date</div>
              <div className="block-info__title">{this.props.date}</div>
            </div>
          </div>
        );
    }
}

export default OrderInfo;