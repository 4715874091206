import React from 'react';
import SwipeToDelete from '../SwipeToDelete';
import axios from 'axios';
import { withRouter } from 'react-router';

const itemHeight = 70;

class StandProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quantity: '',
            izDropzone: false,
        };
        this.itemDelete = this.itemDelete.bind(this);
        this.updateProduct = this.updateProduct.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
    }

    componentDidMount() {
        this.setState({ quantity: this.props.item.quantity });
        if (this.props.standType === 'area')
            this.setState({ izDropzone: true });
    }

    updateProduct = (e) => {
        this.setState({
            quantity: e.target.value
        });
        this.props.updateProduct(this.props.item.id, e.target.value,
            this.props.item.newPaletteId || this.props.item.palette_id,
            this.props.item.newProductId || this.props.item.product_id);
    };

    itemDelete = itemId => {
        // api/ delete/<int:stand_id>/<int:product_id>
        axios.post(`${process.env.REACT_APP_API_URL}/api/delete/${this.props.item.stand_id}/${itemId}`, null, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('token')}`
            }
        })
            .then(response => {

            })
            .catch((error) => {
                if (error.response.status === 403) {
                    console.log('403', error.response.data);
                }
                else {
                    alert(error.response.data);
                }
            });
    }

    onItemClick = itemId => {
        this.props.selectItem(itemId);
    };

    render() {
        const { item,
            // allProducts,
        } = this.props;
        const { izDropzone } = this.state;
        let { quantity } = this.state;
        let className = 'list-group-item shared-table__line';

        return (
            <>
                <SwipeToDelete
                    key={item.id}
                    height={itemHeight}
                    onDelete={() => this.itemDelete(item.id)}
                    onUpdate={() => this.props.history.push(`/moving/${item.id}`)}
                >
                    <div
                        className={className}
                        onClick={() => this.onItemClick(item.palette_id)}
                    >
                        <div className="shared-table__column shared-table__column--inventory--1">
                            {item.name
                                ? item.name
                                : item.id
                            }
                            <div className="shared-table__barcode">Barcode: {item.code
                                ? item.code
                                : 'Not set'
                            }
                            </div>
                        </div>
                        <div className="shared-table__column shared-table__column--inventory--2">
                            <div className="shared-table__quantity">
                                <input
                                    className="item-quantity"
                                    type="number"
                                    inputMode="numeric"
                                    value={quantity}
                                    onChange={(e) => this.updateProduct(e)}
                                />
                            </div>
                        </div>
                    </div>
                </SwipeToDelete>
            </>
        );
    }
}

export default withRouter(StandProduct);