import React from 'react';
import './Nav.scss';
import { Link } from 'react-router-dom';

function Nav(props) {
  const logged_out_nav = (
    <ul></ul>
  );

  const logged_in_nav = (
    <div className="header">
      <div onClick={props.onClick} className="header__left">
        <svg className="header__arrow" style={{ visibility: props.hasIcon ? 'visible' : 'hidden' }}>
          <use href="#arrow">
            <svg id="arrow" viewBox="0 0 37 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.607 8.5l-9.25 8.5 9.25 8.5 2.168-1.992L16.693 17l7.082-6.508L21.607 8.5z" fill="currentColor"></path></svg>
          </use>
        </svg>
        <span>{props.label}</span>
      </div>

      <div className="header__right">
        {(props.hasAction)
          ? (
            <div onClick={props.onClickAction} className="header__link-info">
              <span>{(props.actionLabel)}</span>
            </div>
          )
          : (
            <>
              <div href="#" className="header__link-info">
                <svg className="header__info">
                  <use href="#info">
                    <svg id="info" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.085 17.91c0 9.113-7.12 16.41-15.793 16.41C8.62 34.32 1.5 27.023 1.5 17.91S8.62 1.5 17.292 1.5c8.673 0 15.793 7.297 15.793 16.41z" stroke="currentColor" strokeWidth="3"></path><path d="M19.063 26H16.23V15.434h2.831V26zm-2.998-13.3c0-.424.14-.772.42-1.046.286-.273.673-.41 1.162-.41.481 0 .865.137 1.152.41.286.274.43.622.43 1.045 0 .43-.147.781-.44 1.055-.286.273-.667.41-1.143.41-.475 0-.859-.137-1.152-.41-.286-.274-.43-.625-.43-1.055z" fill="currentColor"></path></svg>
                  </use>
                </svg>
              </div>

              <label className="header__wrap-more">
                <svg className="header__more">
                  <use href="#more">
                    <svg id="more" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.594 16.594c.406-.407.875-.61 1.406-.61.531 0 1 .204 1.406.61.406.406.61.875.61 1.406 0 .531-.204 1-.61 1.406-.406.407-.875.61-1.406.61-.531 0-1-.203-1.406-.61-.406-.406-.61-.875-.61-1.406 0-.531.204-1 .61-1.406zm0-6c.406-.406.875-.61 1.406-.61.531 0 1 .204 1.406.61.406.406.61.875.61 1.406 0 .531-.204 1-.61 1.406-.406.406-.875.61-1.406.61-.531 0-1-.204-1.406-.61-.406-.406-.61-.875-.61-1.406 0-.531.204-1 .61-1.406zm2.812-3.188c-.406.407-.875.61-1.406.61-.531 0-1-.204-1.406-.61-.406-.406-.61-.875-.61-1.406 0-.531.204-1 .61-1.406.406-.407.875-.61 1.406-.61.531 0 1 .204 1.406.61.406.406.61.875.61 1.406 0 .531-.204 1-.61 1.406z" fill="currentColor"></path></svg>
                  </use>
                </svg>
                <input type="checkbox" hidden="hidden" />
                <div className="menu">
                  <Link to="/orders" >
                    <div className="menu__link">Orders</div>
                  </Link>
                  <Link to="/add-item" >
                    <div className="menu__link">Add Item</div>
                  </Link>
                  <Link to="/logout" >
                    <div className="menu__link">LogOut</div>
                  </Link>
                </div>
              </label>
            </>
          )
        }
      </div>


    </div>
  );
  return <div>{props.logged_in ? logged_in_nav : logged_out_nav}</div>;
}

export default Nav;