import React from 'react';
import PropTypes from 'prop-types';
import './LoginForm.scss';


class LoginForm extends React.Component {
  state = {
    username: '',
    password: ''
  };

 componentDidMount() {
    document.body.style.backgroundColor = '#2cb0fa';
 }

 componentWillUnmount() {
    document.body.style.backgroundColor = 'white';
 }

  handle_change = e => {
    this.setState({[e.target.name]: e.target.value});
  };

  render() {
    return (
    <div style={{
          backgroundImage: "url(/static/images/bg.svg)",
          backgroundPosition: 'center',
          backgroundSize: '100% 330px',
          backgroundRepeat: 'no-repeat',
		width: '100%',
        height: '330px'
        }}>
  <header className="auth-head">
    <svg className="auth-head__icon"><use href="#lock"><svg id="lock" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM6 8h1V6c0-2.76 2.24-5 5-5s5 2.24 5 5h-1.9c0-1.71-1.39-3.1-3.1-3.1-1.71 0-3.1 1.39-3.1 3.1v2H18c1.1 0 2 .9 2 2v10c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V10c0-1.1.9-2 2-2zm0 12h12V10H6v10z" fill="currentColor"></path></svg></use></svg>
  </header>

  <div className="auth">
    <picture className="picture auth__logo">
      <img src="/static/images/logo.png" alt="Logo"/>
    </picture>

    <form className="auth__form" onSubmit={e => this.props.handle_login(e, this.state)}>

      <div className="auth__wrap-control">
        <div className="auth__control">
          <label className="control" htmlFor="username">
            <span className="control__label">User name</span>
            <input
                    className="control__input"
                    type="text"
                    name="username"
                    value={this.state.username}
                    onChange={this.handle_change}

            />
          </label>
        </div>

        <div className="auth__control">
          <label className="control" htmlFor="password">
            <span className="control__label">Password</span>
            <input
                    className="control__input"
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handle_change}
            />
          </label>
        </div>
      </div>
      <input type="submit" className="auth__button button button--blue" value="LOGIN" />
      {this.props.error && <div className="auth__info">{this.props.error}</div>}
    </form>
    <svg className="auth__back"><use href="#auth"></use></svg>
  </div>
    </div>
    );
  }
}

export default LoginForm;

LoginForm.propTypes = {
  handle_login: PropTypes.func.isRequired
};