import React from 'react';
import './OrderDetail.scss';
import ProductList from './ProductList/ProductList';
import OrderInfo from './OrderInfo/OrderInfo';
import GreenBox from './GreenBox/GreenBox';
import Map from './Map/Map';
import BarcodeReader from '../BarcodeReader/BarcodeReader';
import Nav from '../Nav/Nav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
//import Loader from '../Loader/Loader';
import {withRouter} from 'react-router';


class OrderDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          order: {},
          title: '',
          showModal: false,
          selectedProduct: null,
          selectedStands: [],
          barcodeOpen: false
        };
    }

    sound = null;

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_URL}/api/orders/${this.props.match.params.id}/`, {
          headers: {
            'Accept' : 'application/json',
            'Authorization': `JWT ${localStorage.getItem('token')}`
          }
        })
        .then(response => {
            //setTimeout(() => this.setState({order: response.data}), 3000);
            this.setState({order: response.data});
        })
        .catch((error) => {
            if(error.response.status === 403) {
                this.props.onLogout();
            }
            else {
                alert(error.response.data);
            }
        });

        this.sound = new Audio(`${process.env.REACT_APP_API_URL}/static/scan16.wav`);
    }

    changeCount = (productId, inc) => {
        let url = '';
        if(inc > 1) {
            url = `${process.env.REACT_APP_API_URL}/api/pick/${this.state.order.id}/${productId}?count=all`
        }
        else {
            url = `${process.env.REACT_APP_API_URL}/api/${inc < 0
                ? 'pick'
                : 'append'}/${this.state.order.id}/${productId}`
        }

        axios.post(url, null, {
            headers: {
                'Content-Type': 'application/json',
                'Accept' : 'application/json',
                'Authorization': `JWT ${localStorage.getItem('token')}`
            }
        }).then(response => {
                this.setState({order: response.data});
        })
        .catch((error) => {
            if(error.response.status === 403) {
                this.props.onLogout();
            }
            else {
                alert(error.response.data);
            }
        });
    }

    increment = (productId) => {
        this.changeCount(productId, 1);
    }

    decrement = (productId) => {
        this.changeCount(productId, -1);
    }

    selectStand = (productId, selected) => {
        let selectedStands = this.state.selectedStands;
        for (let stand of this.state.order.warehouse.stands){
            for (let item of stand.products){
                if (item.product_id == productId) {
                    if(selected) {
                        selectedStands.push(item.stand_id);
                    }
                    else {
                        selectedStands = selectedStands.filter(e => e !== item.stand_id);
                    }
                }
            }
        }

        this.setState({selectedStands});
    }

    closeScanner = () => {
        this.setState({barcodeOpen:false});
    }

    goBack = () => {
        //TODO: use react router
        window.location.href = '/orders';
    }

    beep = () => {
        this.sound.play();
    }

    onScanned = (code) => {
        //code = '9785171120962'; // debug
        this.setState({barcodeOpen: false});
        let product = this.state.order.products.find( (e) => e.code === code );
        if(product) {
            this.beep();
            toast.success(`✅ ${product.name} picked`);
            this.changeCount(product.id, 999);
        }
        else {
            toast.info(`Product with code "${code}" not found on this list`);
        }
    }

    render() {
        if( typeof this.state.order.products === 'undefined') {
            return (<>Loading...</>);
            //TODO: return (<Loader />);
        }

        let complete = true;
        for (var item of this.state.order.products) {
            if (item.left !== 0) {
                complete = false;
                break;
            }
        }

        const title = complete ? 'Select dropzone' : 'Scan to pick';

        return (
            <>
                <Nav
                   logged_in={true}
                   hasIcon={!this.state.barcodeOpen}
                   label={this.state.barcodeOpen ? 'Close Scanner' : 'Back' }
                   onLogout={this.props.onLogout}
                   onClick={() => {
                       this.state.barcodeOpen ? this.closeScanner() : this.goBack()
                   }}
                />
               <div style={{margin: '50px auto'}}>
                <OrderInfo
                    name={this.state.order.id}
                    date={this.state.order.due_date}
                    status={this.state.order.status}
                />

                <Map
                    order={this.state.order}
                    warehouse={this.state.order.warehouse}
                    selectedStands={this.state.selectedStands}
                />
                <ProductList
                    productList={this.state.order.products}
                    increment={this.increment}
                    decrement={this.decrement}
                    selectStand={this.selectStand}
                    changeCount={this.changeCount}
                />
                <GreenBox title={title} onClick={() => {
                    this.setState({barcodeOpen: !this.state.barcodeOpen})
                }} />

                <BarcodeReader visible={this.state.barcodeOpen} onScanned={this.onScanned} />
                <ToastContainer position="bottom-center" autoClose={5000} newestOnTop closeOnClick/>
              </div>
            </>
        )}

}

export default withRouter(OrderDetail);