import React from 'react';

class Order extends React.Component {

    switchColor = (status) => {
        switch(status) {
          case 1:
            return 'yellow';
          case 2:
            return 'green';
          default:
            return 'blue';
        }
    }

    render() {

        const order = this.props.order;
        let color =  this.switchColor(this.props.order.status);
        return (
            <a href={"/orders/" + order.id} className={"orders-tablet__line orders-tablet__line--body orders-tablet__line--" + color}>
                <div className="orders-tablet__item orders-tablet__item--first orders-tablet__item--body">{order.customer_id}</div>
                <div className="orders-tablet__item orders-tablet__item--two orders-tablet__item--body">{order.id}</div>
                <div className="orders-tablet__item orders-tablet__item--three orders-tablet__item--body">
                    <svg width="28" height="19">
                        <use href="#chevron-right">
                            <svg id="chevron-right" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.648 14.25l7-4.75-7-4.75-1.64 1.113 5.36 3.637-5.36 3.637 1.64 1.113z" fill="currentColor">
                                <path d="M11.648 14.25l7-4.75-7-4.75-1.64 1.113 5.36 3.637-5.36 3.637 1.64 1.113z" fill="currentColor"></path>
                            </path></svg>
                        </use>
                    </svg>
                </div>
            </a>
        )

    }

}

export default Order;