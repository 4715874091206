import React from 'react';
import './Stand.scss';
import {
  //Link,
  withRouter,
} from 'react-router-dom';

import Context from '../../Context/Context';

class Stand extends React.Component {


    _onClick(e) {
        let standId = e.target.getAttribute("stand-id");
        this.context.update({stand: this.props.stand, orderIdTmp: this.props.orderId});
        this.props.history.push(`/inventory/${standId}`);
    }

    render() {
        const { stand } = this.props;

        const style = {
            height: stand.h,
            width: stand.w,
            top: stand.y,
            left: stand.x,
            lineHeight: stand.h + 'px',
        };
        let className = 'stand';
        if(this.props.highlighted) {
            className += ' highlighted';
        }
        if(this.props.selected) {
            className += ' selected';
        }
        if(stand.type.toLowerCase() === 'area') {
            className += ' dropzone';
        }

        className += ` type-${stand.type}`;

        return (
                <div style={style}
                    onClick={this._onClick.bind(this)}
                    className={className}
                    stand-id={stand.id}
                >
                    <span>{stand.name}</span>
                </div>
        )
    }
}
Stand.contextType = Context;

export default withRouter(Stand);
