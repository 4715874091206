import React, { Fragment } from 'react';
import './AddItem.scss';
import Nav from '../Nav/Nav';
import GreenBox from '../OrderDetail/GreenBox/GreenBox';
import ScanBarcodeButton from '../buttons/ScanBarcodeButton';
import Context from '../Context/Context';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import {withRouter} from 'react-router';


class AddItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedProduct: null,
          newProduct: null,
          selectedStand: null,
          selectedPalette: null,
          isPaletteDisabled: true,
          isNewItem: false,
          isDropZone: false,
          paletteOptions: null,
          itemOptions: null,
          quantity: 0,
          barcode: '',
          stands: {},
        };
        this.onNameChange = this.onNameChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.addItem = this.addItem.bind(this);
    }

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_URL}/api/stands/`, {
          headers: {
            'Accept' : 'application/json',
            'Authorization': `JWT ${localStorage.getItem('token')}`
          }
        })
        .then(response => {
            let stands = response.data;
            let selectedStand = null;
            for(let stand of stands) {
                if(stand.id == this.props.match.params.id) {
                    selectedStand = stand;
                    break;
                }
            }
            if (selectedStand.type === 'area') {
                this.setState({isDropZone: true});
            }
            axios.get(`${process.env.REACT_APP_API_URL}/api/products/`, {
              headers: {
                'Accept' : 'application/json',
                'Authorization': `JWT ${localStorage.getItem('token')}`
              }
            })
            .then(response => {
                let itemOptions = response.data.map(obj => {
                    obj['value'] = obj['id']
                    obj['label'] = obj['name'];
                    return Object.assign({}, obj);
                });
                let paletteOptions = selectedStand.palettes;
                this.setState({stands, selectedStand, itemOptions, paletteOptions, isPaletteDisabled: false});

                let queryParams = this.props.location.search;
                queryParams = parseInt(queryParams.replace('?pid=', ''));

                if(queryParams) {
                    let selectedPalette = paletteOptions.find(item => item.id === queryParams);
                    this.setState({selectedPalette});
                }

            })
        })
        .catch((error) => {
            if(error.response.status === 403) {
                this.props.onLogout();
            }
            else {
                alert(error.response.data);
            }
        });
    }

    getSelectedProductName = () => {
        if(this.state.isNewItem) {
            return this.state.newProduct ? this.state.newProduct : null;
        }
        else {
            return this.state.selectedProduct ? this.state.selectedProduct : null;
        }
    }

    onChangeStand = selectedStand => {
        let paletteOptions = this.state.stands.find(item => item.id === selectedStand.id).palettes;
        this.setState(
          { selectedStand, paletteOptions, isPaletteDisabled: false }
        );
        if (selectedStand.type === 'stand') {
            this.setState({ isDropZone: false });
        } else {
            this.setState({ isDropZone: true });
        }
    };

    onChangePalette = selectedPalette => {
        this.setState({ selectedPalette });
    };

    onNameChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'create-option') {
            this.setState({ isNewItem: true, newProduct: newValue, barcode: '' });
        }
        if (actionMeta.action === 'select-option') {
            this.setState({
                selectedProduct: newValue,
                isNewItem: false,
                barcode: newValue.code ? newValue.code: 'Not set up'
            });
        }
        if (actionMeta.action === 'clear') {
            this.setState({
                selectedProduct: '',
                isNewItem: false,
            barcode: '' });
        }
    };

    onInputChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    addProduct = () => {
        let url = `${process.env.REACT_APP_API_URL}/api/products/`;
        axios.post(url,
            {
              name: this.state.newProduct.value,
              code: this.state.barcode,
              stock: 0, // will be set in the next api request
            }, {
            headers: {
                'Accept' : 'application/json',
                'Authorization': `JWT ${localStorage.getItem('token')}`
            }
        })
        .then(response => {
            this.setState({selectedProduct: response.data,});
            this.addItemApi();
            toast.success(`✅ ${this.state.selectedProduct.name || this.state.newProduct.value} created`);
        })
        .catch((error) => {
            alert(error.response.data);
        });
    }

    addItemApi = () => {
        let url = `${process.env.REACT_APP_API_URL}/api/add/${this.state.selectedStand.id}/`;
            url += `?quantity=${this.state.quantity}&product_id=${this.state.selectedProduct.id}`;
            if (this.state.selectedPalette) {
                url +=`&palette_id=${this.state.selectedPalette.id}`;
            }

        axios.post(url, null, {
            headers: {
                'Accept' : 'application/json',
                'Authorization': `JWT ${localStorage.getItem('token')}`
            }
        })
        .then(response => {
            toast.success(`✅ ${this.state.selectedProduct.name} added`);
            this.setState({
                selectedProduct: null,
                newProduct: null,
                isNewItem: false,
                quantity: 0,
                barcode: '',
            });
            this.context.update({stand: response.data});
        })
        .catch((error) => {
            alert(error.response.data);
        });

    }

    addItem = () => {
        if( (this.state.selectedProduct !== null || this.state.newProduct !== null)
            && this.state.selectedStand !== null
            && this.state.quantity > 0
            ) {
            if (this.state.isNewItem) {
                this.addProduct();
            } else {
                this.addItemApi();
            }
        } else {
            toast.warning('❌ All parameters are required');
        }
    }

    render(){
        const btnTitle = 'Confirm';
        const {
            selectedStand,
            selectedPalette,
            isPaletteDisabled,
            isNewItem,
            paletteOptions,
            itemOptions,
            stands,
            isDropZone,
        } = this.state;

        const customStyles = {
            valueContainer: base => ({
              ...base,
              height: '54px',
            }),
        }

        const name = this.getSelectedProductName();

        return (
            <>
                <Nav
                    logged_in={true}
                    hasIcon={true}
                    label={ 'Add Item' }
                    onClick={() => { this.props.history.goBack() }}
                />
                <ToastContainer position="top-center" autoClose={3000} newestOnTop closeOnClick/>
                <div style={{marginTop: '65px'}}>
                    <div className="order-header">
                        <div className="order-header__left">
                            <div className="order-header__title">Hi, user.name 23</div>
                            <div className="order-header__desc">Work shift: 29-01-2021</div>
                        </div>
                        <div className="order-header__right">
                            <label className="toggle">
                            <span className="toggle__title">Pause/Continue</span>
                                <input type="checkbox" hidden defaultChecked/>
                                <span className="toggle__wrap">
                                    <span className="toggle__tack"></span>
                                    <span className="toggle__thumb"></span>
                                </span>
                            </label>
                        </div>
                    </div>

                    <div className="container">
                        <div className="title">
                            New Item
                        </div>

                        <div className='label'>Name</div>
                        <div className="block">
                            <div className="col-input" style={{width: '100%'}}>
                                <CreatableSelect
                                    tabIndex="1"
                                    placeholder="Select or Create"
                                    isClearable
                                    value={name}
                                    onChange={this.onNameChange}
                                    options={itemOptions}
                                    styles={customStyles}

                                />
                            </div>
                        </div>

                        <div className='label'>Amount</div>
                        <div className="block">
                            <div className="col-input" style={{width: '100%'}}>
                                <input
                                    tabIndex="2"
                                    className="input"
                                    type="number"
                                    name="quantity"
                                    value={this.state.quantity}
                                    onChange={this.onInputChange}
                                />
                            </div>
                        </div>

                        <div className='label'>Barcode</div>
                        <div className="block">
                            <div className="col-input">
                                <input
                                    tabIndex="3"
                                    className="input"
                                    type="text"
                                    disabled={isNewItem ? '' : 'disabled'}
                                    name="barcode"
                                    value={this.state.barcode}
                                    onChange={this.onInputChange}
                                />
                            </div>
                            <div className="col-btn">
                                <ScanBarcodeButton />
                            </div>
                        </div>

                        <div className='label'>Stand Name</div>
                        <div className="block">
                            <div className="col-input">
                                <Select
                                    tabIndex="4"
                                    placeholder="Select stand..."
                                    value={selectedStand}
                                    onChange={this.onChangeStand}
                                    options={stands}
                                    getOptionValue={option => option['id']}
                                    getOptionLabel={option => `${option.name}`}
                                    styles={customStyles}
                                    menuPlacement="top"
                                />
                            </div>
                            <div className="col-btn">
                                <ScanBarcodeButton />
                            </div>
                        </div>

                        {isDropZone ? '' : (<>
                            <div className='label'>Palette Code</div>
                            <div className="block">
                                <div className="col-input">
                                    <Select
                                        tabIndex="5"
                                        placeholder="Select palette..."
                                        isDisabled={isPaletteDisabled ? true : false}
                                        value={selectedPalette}
                                        onChange={this.onChangePalette}
                                        options={paletteOptions}
                                        getOptionValue={option => option['id']}
                                        getOptionLabel={option => `${option.code}`}
                                        styles={customStyles}
                                        menuPlacement="top"
                                    />
                                </div>
                                <div className="col-btn">
                                    <ScanBarcodeButton />
                                </div>
                            </div>
                        </>)}
                    </div>
                </div>

                <GreenBox
                    title={btnTitle}
                    onClick={() => this.addItem()}
                />
            </>
        );
    }
}
AddItem.contextType = Context;

export default withRouter(AddItem);