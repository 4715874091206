import React from 'react';
import './toggle.scss';



class Toggle extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isChecked: true,
    };
  }

  toggleChange = () => {
    this.props.onChange(!this.state.isChecked);
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }

    render(){
        return (
            <label className="toggle">
              <span className="toggle__title">Pause/Continue</span>
              <input type="checkbox"
                  checked={this.state.isChecked}
                  onChange={this.toggleChange}
                  hidden="hidden"
                />

              <span className="toggle__wrap">
                <span className="toggle__tack"></span>
                <span className="toggle__thumb"></span>
              </span>
            </label>
        );
    }
}

export default Toggle;