import React, { Fragment } from 'react';
import './Moving.scss';
import Nav from '../Nav/Nav';
import GreenBox from '../OrderDetail/GreenBox/GreenBox';
import ScanBarcodeButton from '../buttons/ScanBarcodeButton';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import {withRouter} from 'react-router';


class Moving extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          itemId: this.props.match.params.id,
          item: {},
          standName: '',
          paletteCode: '',
          selectedStand: null,
          selectedPalette: null,
          isPaletteDisabled: true,
          paletteOptions: null,
          stands: {},
        };
    }

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_URL}/api/stands/`, {
          headers: {
            'Accept' : 'application/json',
            'Authorization': `JWT ${localStorage.getItem('token')}`
          }
        })
        .then(response => {
            this.setState({stands: response.data});
        })
        .catch((error) => {
            alert(error.response.data);
        });
        axios.get(`${process.env.REACT_APP_API_URL}/api/standitems/${this.props.match.params.id}/`, {
          headers: {
            'Accept' : 'application/json',
            'Authorization': `JWT ${localStorage.getItem('token')}`
          }
        })
        .then(response => {
            this.setState({item: response.data});
        })
        .catch((error) => {
            alert(error.response.data);
        });
    }

    onChangeStand = selectedStand => {
        let stand = this.state.stands.find(item => item.id === selectedStand.id);
        if (selectedStand.type === 'area') {
            this.setState({ isPaletteDisabled: true });
        } else {
            this.setState({ isPaletteDisabled: false });
        }
        this.setState(
          { selectedStand, paletteOptions: stand.palettes }
        );
    };


    onChangePalette = selectedPalette => {
        this.setState({ selectedPalette });
    };

    confirmMoving = () => {
        if(this.state.selectedStand !== null) {
            // api/move/<int:item_id>/<int:palette_id>/
            let url = `${process.env.REACT_APP_API_URL}/api/move/${this.state.itemId}/`;
            url +=`${this.state.selectedStand.id}/?`;
            if (this.state.selectedStand.type === 'stand')
                url +=`&pid=${this.state.selectedPalette.id}`;

            axios.patch(url, null, {
              headers: {
                'Accept' : 'application/json',
                'Authorization': `JWT ${localStorage.getItem('token')}`
              }
            })
            .then(response => {
                this.setState({ isPaletteDisabled: true });
                toast.success(`✅ Product #${this.state.item.name} was moved`);
                setTimeout(() => window.location.href = `/inventory/${this.state.selectedStand.id}`, 1500);
            })
            .catch((error) => {
                if(error.response.status === 403) {
                    console.log('403', error.response.data);
                }
                else {
                    alert(error.response.data);
                }
            });

        }
        else {
            toast.warning('❌ All parameters are required');
        }
    }

    render(){
        const bntTitle = 'Confirm';
        const {
            //itemId,
            item,
            selectedStand,
            selectedPalette,
            isPaletteDisabled,
            paletteOptions,
            stands,
        } = this.state;

        const customStyles = {
            valueContainer: base => ({
              ...base,
              height: '54px',
            }),
        }

        return (
            <>
                <Nav
                    logged_in={true}
                    hasIcon={true}
                    label={ 'Moving' }
                    onClick={() => { this.props.history.goBack() }}
                />
                <ToastContainer position="top-center" autoClose={3000} newestOnTop closeOnClick/>
                <div style={{marginTop: '65px'}}>
                    <div className="order-header">
                        <div className="order-header__left">
                            <div className="order-header__title">Hi, user.name 23</div>
                            <div className="order-header__desc">Work shift: 29-01-2021</div>
                        </div>
                        <div className="order-header__right">
                            <label className="toggle">
                            <span className="toggle__title">Pause/Continue</span>
                                <input type="checkbox" hidden defaultChecked/>
                                <span className="toggle__wrap">
                                    <span className="toggle__tack"></span>
                                    <span className="toggle__thumb"></span>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="title">
                        Move <span>{item.name}</span> to:
                    </div>

                    <div className='label'>Stand</div>
                    <div className="block">
                        <div className="col-input">
                            <Select
                                placeholder="Select stand..."
                                value={selectedStand}
                                onChange={this.onChangeStand}
                                options={stands}
                                getOptionValue={option => option['id']}
                                getOptionLabel={option => `${option.name}`}
                                styles={customStyles}
                            />
                        </div>
                        <div className="col-btn">
                            <ScanBarcodeButton />
                        </div>
                    </div>

                    <div className='label'>Palette</div>
                    <div className="block">
                      <div className="col-input">
                        <Select
                            placeholder="Select palette..."
                            isDisabled={isPaletteDisabled ? true : false}
                            value={selectedPalette}
                            onChange={this.onChangePalette}
                            options={paletteOptions}
                            getOptionValue={option => option['id']}
                            getOptionLabel={option => `${option.code}`}
                            styles={customStyles}
                        />
                      </div>
                      <div className="col-btn">
                        <ScanBarcodeButton />
                      </div>
                    </div>
                </div>

                <GreenBox
                    title={bntTitle}
                    onClick={() => this.confirmMoving()}
                />
            </>
        );
    }
}

export default withRouter(Moving);