import React from 'react';
import moment from 'moment';
import './Header.scss';
import Toggle from './toggle/toggle';


class Header extends React.Component {

    render() {

        const date = moment().format("DD-MM-YYYY");

        return (
            <div className="order-header">
                <div className="order-header__left">
                  <div className="order-header__title">Hi, user.name 23</div>
                  <div className="order-header__desc">Work shift: {date}</div>
                </div>

                <div className="order-header__right">
                  <Toggle onChange={this.props.onPause} />
                </div>
            </div>
        );

    }

}

export default Header;