import React from 'react';
import StandProduct from './StandProduct';
import axios from 'axios';


class StandProductList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          quantity: '',
          allProducts: {},
        };
    }

    componentDidMount() {
        if (!this.state.allProducts.length) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/products/`, {
              headers: {
                'Accept' : 'application/json',
                'Authorization': `JWT ${localStorage.getItem('token')}`
              }
            })
            .then(response => {
                this.setState({allProducts: response.data});
            })
            .catch((error) => {
                alert(error.response.data);
            });
        }
    }

    render(){
        const {
            stand, selectedPaletteCode: paletteCode,
            updateProduct, selectItem,
            } = this.props;
        let productList = stand.products;
        const { allProducts } = this.state;

        if(typeof productList === 'undefined' || productList.length === 0) {
            return <div style={{height:'7rem',lineHeight:'7rem',textAlign:'center'}}>Stand is Empty / Empty products</div>;
        }

        if (paletteCode) {
            productList = productList.filter(item => item.palette_code === paletteCode);
            if(typeof productList === 'undefined' || productList.length === 0) {
                return <div style={{height:'3rem',lineHeight:'3rem',textAlign:'center'}}>Palette is Empty</div>;
            }
        }

        return (
            <div className="shared-table shared-table--checkbox">
                <div className="shared-table__body">
                    {productList.map(item =>
                        <StandProduct
                            item={item}
                            key={item.id}
                            updateProduct={updateProduct}
                            selectItem={selectItem}
                            allProducts={allProducts}
                            standType={stand.type}
                        />
                    )}
                </div>

            </div>
        );
    }
}

export default StandProductList;