import React from 'react';
import Order from './Order';


class OrderList extends React.Component {

    render() {

        if(typeof this.props.orders === 'undefined' || this.props.orders.length === 0) {
            return <>Empty orders</>;
        }

        return (
        <>
            <div className="orders-tablet">
                <div className="orders-tablet__line orders-tablet__line--header">
                    <div className="orders-tablet__item orders-tablet__item--first orders-tablet__item--header">Asiakas</div>
                    <div className="orders-tablet__item orders-tablet__item--two orders-tablet__item--header">Order #</div>
                    <div className="orders-tablet__item orders-tablet__item--three orders-tablet__item--header"></div>
                </div>
                <div className="orders-tablet__body">
                    {this.props.orders.map(item =>
                        <Order order={item} key={item.id} />
                    )}
                </div>
            </div>
        </>
        )

    }

}

export default OrderList;