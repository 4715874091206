import React from 'react';
import './Map.scss';
import Stand from '../Stand/Stand';
import QuickPinchZoom, { make3dTransformValue } from "../../PinchZoom";

class Map extends React.Component {
    constructor(props) {
        super(props);
        this.onZoom = this.onZoom.bind(this);
        this.mapRef = React.createRef();
        this.readInitialMapPosition();
    }

    readInitialMapPosition = () => {
        const data = localStorage.getItem(`map_${this.props.order.warehouse_id}`);
        if(data) {
            this.initialMapPosition = JSON.parse(data);
        }
        else {
            this.initialMapPosition = {x: 0, y: 0, scale: 1};
        }
    }

    saveMapPosition = (x, y, scale) => {
        localStorage.setItem(`map_${this.props.order.warehouse_id}`, JSON.stringify({x, y, scale}));
    }

    highlightedStands = [];

    findHighlightedStands() {
        this.highlightedStands = [];
        const warehouseStands = this.props.order.warehouse.stands;
        const products = this.props.order.products;
        const that = this;
        warehouseStands.forEach( function (item){
            var id = item.id;
            item.products.forEach( function (item){
                if(id === item.stand_id) {
                    that.highlightedStands.push(id);
                }
            });
        });
    }

    onZoom = ({x, y, scale}) => {
        if(!this.mapRef.current) {
            return;
        }
        this.mapRef?.current?.style.setProperty('transform', make3dTransformValue({x, y, scale}));
        this.saveMapPosition(x, y, scale);
    }

    render() {
        this.findHighlightedStands();

        const { order } = this.props;

        const divStyle = {
          backgroundImage: 'url(' + this.props.warehouse.map + ')',
          width: this.props.warehouse.map_width,
          height: this.props.warehouse.map_height,
        };
        let minZoom = window.innerWidth/this.props.warehouse.map_width;

        let {x, y, scale} = this.initialMapPosition;

        return (
            <QuickPinchZoom onUpdate={this.onZoom}
                maxZoom={4.5}
                minZoom={minZoom}
                zoomOutFactor={minZoom}
                startX={x}
                startY={y}
                startZoom={scale}
            >
                <div id="map" className="map-background" style={divStyle} ref={this.mapRef}>
                    {this.props.warehouse.stands.map(item =>
                        <Stand
                            stand={item}
                            key={item.id}
                            orderId={order.id}
                            selected={this.props.selectedStands.indexOf(item.id) >= 0}
                            highlighted={this.highlightedStands.indexOf(item.id) >= 0}
                        />
                    )}
                </div>
            </QuickPinchZoom>
        );
    }
}
export default Map;
