import React from 'react';
import './BarcodeReader.scss';
import Quagga from 'quagga';

class BarcodeReader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }

    inited = false;
    started = false;

    onDetected = (data) => {
        if(!this.inited) {
            return;
        }

        this.inited = false;
        this.stopScanner();
        this.props.onScanned(data.codeResult.code);
    }

    startScanner() {
        window.onerror = alert;
        Quagga.init(
        {
            inputStream : {
                name : "Live",
                type : "LiveStream",
                target: document.getElementById('reader'),
                constraints: {
                    facingMode: "environment"
                }
            },
            decoder : {
                readers : ['ean_reader',  'code_39_reader', 'code_128_reader']
            },
        }
        , (err) => {
            if (err) {
                console.log(err);
                return;
            }
            Quagga.start();
        });

        Quagga.onDetected(this.onDetected);
    }

    stopScanner() {
        Quagga.stop();
    }

    render() {

        if(this.props.visible) {
            this.startScanner();
            this.inited = true;
        }
        else {
            if(this.inited) {
                this.inited = false;
                this.stopScanner();
            }
        }

        return (
            <div className={this.props.visible ? "barcode-reader-wrapper active" : "barcode-reader-wrapper"}>
                <div id="reader"/>
            </div>
        )
    }
}

export default BarcodeReader;