import React from 'react';
import './Orders.scss';
import OrderList from './OrderList';
import Header from './../Header/Header';
import axios from 'axios';
import Nav from '../Nav/Nav';
import Context from '../Context/Context';


class OrderTablet extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            errors: {},
            paused: true,
            orders: [],
        };
        this.pause = this.pause.bind(this);
      }

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_URL}/api/orders/`, {
          headers: {
            'Accept' : 'application/json',
            'Authorization': `JWT ${localStorage.getItem('token')}`
          }
        })
        .then(response => {
            this.setState({orders: response.data});
            const defOrderTmp = response.data[0];
            this.context.update({ orderIdTmp: defOrderTmp.id });
        })
        .catch((error) => {
            if(error.response.status === 403) {
                this.props.onLogout();
            }
            else {
                alert(error.response.data);
            }
        });
    }

    countItems(arr){
        return this.state.orders.filter(i => i.status === arr).length;
    }

    pause = (isPaused) => {
        this.setState({paused: isPaused});
    }

  render() {
    const { orders, paused } = this.state;

    let status = {
      NOT_SARTED: 0,
      IN_PROGRESS: 1,
      READY: 2,
    };

    return (
        <div style={ { overflow: !paused ? 'hidden' : 'inherit' } }>
            <Nav
                   logged_in={true}
                   hasIcon={false}
                   label={''}
            />
            <div style={{marginTop: '65px'}}>
                <Header onPause={this.pause} />
                <div className={paused ? 'orders':'orders blurred'}>
                    <div className="orders__top">
                        <div className="orders__title">Keräilylista</div>
                    </div>
                    <div className="orders__list">
                        <div className="die die--blue">Not started ({this.countItems(status.NOT_SARTED)})</div>
                        <div className="die die--yellow">In Progress ({this.countItems(status.IN_PROGRESS)})</div>
                        <div className="die die--green">Ready ({this.countItems(status.READY)})</div>
                    </div>
                    <OrderList orders={orders} />
                </div>
            </div>
        </div>
    );
  }
}
OrderTablet.contextType = Context;

export default OrderTablet;
