import React from 'react';
import Modal from 'react-modal';
import './Product.scss';

Modal.defaultStyles.overlay.backgroundColor = '#f1f1f1';

class Product extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            count: this.props.product.left,
            isDone: false,
            modalIsOpen: false,
            productSelected: false,
            active: false,
        };
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.сloseModal = this.сloseModal.bind(this);
    }

    productClick = (e, productId) => {
        const currentState = this.state.active;
        this.setState({ active: !currentState });
        this.props.selectStand(productId, !currentState);
    };

    componentDidUpdate() {
        if(this.state.count !== this.props.product.left) {
            this.setState({count: this.props.product.left});
        }
    }

    increment() {
        this.setState({
          count: this.state.count + 1
        });
        this.props.increment(this.props.product.id);
    };

    decrement() {
        this.setState({
          count: this.state.count - 1
        });
        this.props.decrement(this.props.product.id);
    };

    changeCount() {
        console.log(this.state.count);
        console.log(this.props.product.left);
        this.setState({count: this.state.count - this.props.product.left})
        this.props.changeCount(this.props.product.id, this.props.product.left);
    };

    handleOpenModal () {
        this.setState({ showModal: true });
    }

    сloseModal () {
        this.setState({ showModal: false });
    }

    render() {

        const { product } = this.props;
        if (product.left === 0) {
            this.isDone = true;
        } else {
            this.isDone = false;
        }

        return (
          <div
            className={this.state.active ? 'shared-table__line active': "shared-table__line"}
            onClick={((e) => this.productClick(e, product.id))}
          >
            <div className="shared-table__column shared-table__column--1">{product.name}</div>
            <div className="shared-table__column shared-table__column--2">{product.location}</div>
            <div className="shared-table__column shared-table__column--3" onClick={this.handleOpenModal}>
              <svg className="shared-table__icon" width="25" height="22"><use href="#view">
                <svg id="view" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.656 19.25H2.344C1.049 19.25 0 18.327 0 17.187V4.813C0 3.673 1.05 2.75 2.344 2.75h20.312c1.295 0 2.344.923 2.344 2.063v12.375c0 1.139-1.05 2.062-2.344 2.062zM5.47 5.156c-1.51 0-2.735 1.078-2.735 2.407 0 1.328 1.225 2.406 2.735 2.406 1.51 0 2.734-1.078 2.734-2.406 0-1.33-1.224-2.407-2.734-2.407zM3.125 16.5h18.75v-4.813l-4.273-3.76a.644.644 0 00-.829 0l-6.617 5.823-2.71-2.385a.644.644 0 00-.83 0l-3.491 3.072V16.5z" fill="currentColor">
                    <path d="M22.656 19.25H2.344C1.049 19.25 0 18.327 0 17.187V4.813C0 3.673 1.05 2.75 2.344 2.75h20.312c1.295 0 2.344.923 2.344 2.063v12.375c0 1.139-1.05 2.062-2.344 2.062zM5.47 5.156c-1.51 0-2.735 1.078-2.735 2.407 0 1.328 1.225 2.406 2.735 2.406 1.51 0 2.734-1.078 2.734-2.406 0-1.33-1.224-2.407-2.734-2.407zM3.125 16.5h18.75v-4.813l-4.273-3.76a.644.644 0 00-.829 0l-6.617 5.823-2.71-2.385a.644.644 0 00-.83 0l-3.491 3.072V16.5z" fill="currentColor"></path>
                </path></svg>
              </use></svg>
            </div>


            <div className="shared-table__column shared-table__column--3" style={{display: 'none' }}>
                <button className='int__btn' onClick={(e) => this.changeCount(e)}><b>∅</b></button>
            </div>


            <div className="shared-table__column shared-table__column--4">
                <div className="int">
                    <div className={this.isDone ? "int__btn int__btn--left int__btn--left--hidden" : "int__btn int__btn--left"}
                        onClick={(e) => this.decrement(e)}>-</div>

                    <p className="product-count">{this.state.count}</p>
                    {!this.isDone && (
                    <div className="int__btn int__btn--right" onClick={(e) => this.increment(e)}>+</div>
                    )}
                </div>
                {this.isDone && (
                <label className="checkbox">
                    <input type="checkbox" defaultChecked="checked" hidden="hidden"/>
                    <span className="checkbox__wrap">
                      <span className="checkbox__back"></span>
                      <svg className="checkbox__front"><use href="#successful">
                        <svg id="successful" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path transform="matrix(.59676 .80242 -.72237 .6915 0 9.76)" stroke="currentColor" strokeWidth="4" d="M0-2h12.761"></path><path transform="matrix(.66857 -.74365 .65383 .75664 7.615 20)" stroke="currentColor" strokeWidth="4" d="M0-2h21.515">
                            <path transform="matrix(.59676 .80242 -.72237 .6915 0 9.76)" stroke="currentColor" strokeWidth="4" d="M0-2h12.761"></path>
                            <path transform="matrix(.66857 -.74365 .65383 .75664 7.615 20)" stroke="currentColor" strokeWidth="4" d="M0-2h21.515"></path>
                        </path></svg>
                      </use></svg>
                    </span>
                </label>
                )}


            </div>
            <Modal
               isOpen={!!this.state.showModal}
               contentLabel="Modal #1 Global Style Override Example"
               onRequestClose={this.сloseModal}
               ariaHideApp={false}
            >
              <img src={product.image} onClick={this.сloseModal} alt=""/>
            </Modal>
          </div>
        );
    }
}

export default Product;