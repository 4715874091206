import React from 'react';
//import {render} from 'react-dom';
//import {Redirect} from 'react-router-dom';
import './Inventory.scss';
import StandProductList from './StandProductList';
import ShelfView from './ShelfView/ShelfView';
import Nav from '../Nav/Nav';
import Context from '../Context/Context';
import GreenBox from '../OrderDetail/GreenBox/GreenBox';
import {withRouter} from 'react-router';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';


class Inventory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          stand: {},
          loading: true,
          selectedItemId: '',
          //selectedProductId: '',
          //selectedPaletteId: '',
          paletteCode: '',
        };
        this.updateProduct = this.updateProduct.bind(this);
        this.saveQuantity = this.saveQuantity.bind(this);
        this.addItem = this.addItem.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.selectedPaletteCode = this.selectedPaletteCode.bind(this);
    }

    componentDidMount() {
        if (!this.context.stand.id) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/stands/${this.props.match.params.id}/`, {
              headers: {
                'Accept' : 'application/json',
                'Authorization': `JWT ${localStorage.getItem('token')}`
              }
            })
            .then(response => {
                this.setState({
                    stand: response.data,
                    loading: false,
                });
                this.context.update({stand: response.data});
            })
            .catch((error) => {
                alert(error.response.data);
            });
        }
        else {
            this.setState({stand: this.context.stand, loading: false});
        }
    }

    saveQuantity = () => {
        let requests = [];
        this.state.stand.products.map(item => {
           if(item.newQuantity || item.newPaletteId || item.newProductId) {
               //api/ change/<int:stand_id>/<int:product_id>/<int:quantity>
               let url = `${process.env.REACT_APP_API_URL}/api/inentory/change/${item.id}/`;
               url += `?q=${item.newQuantity || item.quantity}&pid=${item.newProductId || item.product_id}`;
               if(this.state.stand.type === 'stand') {
                  url += `&pal=${item.newPaletteId || item.palette_id}`;
               }
               requests.push(axios.post(url, null, {
                  headers: {
                    'Accept' : 'application/json',
                    'Authorization': `JWT ${localStorage.getItem('token')}`
                  }
               }))
           }
           return item;
        });

        axios.all(requests).then(responses => {
            toast.success(`✅ Changes saved`);
            let stand = responses[responses.length-1].data
            this.context.update({stand});
            this.setState({
                stand: stand,
                loading: false,
            });
        })
        .catch((error) => {
            console.error(error);
            if(error.response.status === 403) {
                console.log('403', error.response.data);
            }
            else {
                alert(error.response.data);
            }
        });

    };
    
    updateProduct(itemId, newQuantity, palette_id, product_id) {
        const products = Object.assign(this.state.stand.products);

        for(let product of products) {
            if(product.id === itemId) {
                product.newQuantity = parseInt(newQuantity);
                product.newPaletteId = parseInt(palette_id);
                product.newProductId = parseInt(product_id);
            }
        }
        this.setState(prevState => ({
            stand: {
                ...prevState.stand,
                products: products
            }
        }));
    }

    addItem = () => {
        let p = this.state.stand.palettes.find(p => p.code === this.state.paletteCode);
        let url = `/add-item/${this.props.match.params.id}/`;
        if (p) {
            url += `?pid=${p.id}`;
        }

        this.props.history.push(url);
    }
    
    selectItem = (itemId) => {
        this.setState({selectedItemId: itemId});
    }

    selectedPaletteCode = (paletteCode) => {
        this.setState({paletteCode: paletteCode});
    }

    render(){
        const { stand } = this.state;
        const bntTitle = 'Save';
        let shelfViewer = '';

        if (stand.type === 'stand') {
            shelfViewer = <ShelfView stand={stand}
                selectedItemId={this.state.selectedItemId}
                selectedPaletteCode={this.selectedPaletteCode}
            />;
        }

        let backUrl = '/orders/';
        if (this.context.orderIdTmp) {
            backUrl += `${this.context.orderIdTmp}/`;
        }

        return (
            <>
                <Nav
                    logged_in={true}
                    hasIcon={true}
                    hasAction={true}
                    actionLabel={ '+ Add' }
                    onClickAction={() => this.addItem()}
                    label={ 'Inventory management' }
                    onClick={() => { this.props.history.push(backUrl) }}
                />
                <ToastContainer position="top-center" autoClose={5000} newestOnTop closeOnClick/>
                <div style={{marginTop: '65px'}}>
                    <div className="order-header">
                        <div className="order-header__left">
                            <div className="order-header__title">Hi, user.name 23</div>
                            <div className="order-header__desc">Work shift: 09-01-2021</div>
                        </div>
                        <div className="order-header__right">
                            <label className="toggle">
                            <span className="toggle__title">Pause/Continue</span>
                                <input type="checkbox" hidden defaultChecked/>
                                <span className="toggle__wrap">
                                    <span className="toggle__tack"></span>
                                    <span className="toggle__thumb"></span>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="order-header">
                    <div className="order-header__left">
                        <div className="order-header__title" style={{textTransform:'uppercase'}}>{stand.type} {stand.name}</div>
                        <div className="order-header__desc">ID: #{stand.id} | Type: {stand.type}</div>
                    </div>
                </div>

                {shelfViewer}

                {this.state.loading
                    ? <p>Loading...</p>
                    : <StandProductList
                        stand={stand}
                        saveStandItem={this.saveProductItem}
                        updateProduct={this.updateProduct}
                        selectItem={this.selectItem}
                        selectedPaletteCode={this.state.paletteCode}
                    />
                }

                <GreenBox
                    title={bntTitle}
                    onClick={() => this.saveQuantity()}
                />
            </>
        );
    }
}
Inventory.contextType = Context;

export default withRouter(Inventory);
