import React from 'react';
import Product from '../Product/Product';


class ProductList extends React.Component {

    render() {
        const { productList,
            // newItem,
            } = this.props;

        if(typeof productList === 'undefined' || productList.length === 0) {
            return <>Empty items</>;
        }
        return (

        <div className="shared-table shared-table--checkbox">
            <div className="shared-table__header">
              <div className="shared-table__line">
                <div className="shared-table__column shared-table__column--1">Item name</div>
                <div className="shared-table__column shared-table__column--2">Shelf code</div>
                <div className="shared-table__column shared-table__column--3">View</div>
                <div className="shared-table__column shared-table__column--4">Amount</div>
              </div>
            </div>

            <div className="shared-table__body">
              {this.props.productList.map((item, i) =>
                <Product
                    product={item}
                    key={i}
                    increment={this.props.increment}
                    decrement={this.props.decrement}
                    changeCount={this.props.changeCount}
                    selectStand={this.props.selectStand}
                />
              )}
            </div>
        </div>

        );
    }
}

export default ProductList;