import React from 'react';
import './GreenBox.scss';


class GreenBox extends React.Component {

    render() {
        return (
          <div style={{align:'center'}} className="green-box" onClick={this.props.onClick}>
            {this.props.title}
          </div>
        );
    }
}
export default GreenBox;
